<template>
    <div>
        <v-row>
            <v-col cols="2">
                <v-menu offset-y dense>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            small
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                            Server: {{ selectedServerName }}
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(item, index) in serverData"
                            v-on:click="changeServer(item)"
                            :key="index"
                        >
                            <v-list-item-title>{{
                                item.server_name
                            }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                <v-text-field
                    class=""
                    v-model="copyTradeSearch"
                    outlined
                    dense
                    append-icon="mdi-search"
                    label="Search Login"
                    single-line
                    hide-details="auto"
                >
                </v-text-field>
            </v-col>

            <v-col cols="5"></v-col>

            <v-col cols="4" class="text-right">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="blue-grey"
                            :loading="loading"
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="resetFileInput"
                        >
                            <v-icon dark dense> mdi-file-upload </v-icon>
                            <input
                                type="file"
                                ref="bookFile"
                                @change="
                                    (event) => readFile(event.target.files[0])
                                "
                                accept=".csv"
                                style="display: none"
                            />
                        </v-btn>
                    </template>
                    <span>Upload settings from CSV</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="blue-grey"
                            class="ml-2"
                            :loading="loading"
                            small
                            v-bind="attrs"
                            v-on="on"
                        >
                            <download-excel
                                :data="filteredCopyTradeConfig"
                                :fields="csvFields"
                                type="csv"
                                style="display: inline"
                                name="CopyTradeConfig.csv"
                            >
                                <v-icon dark dense>
                                    mdi-microsoft-excel
                                </v-icon>
                            </download-excel>
                        </v-btn>
                    </template>
                    <span>Export settings as CSV</span>
                </v-tooltip>

                <v-btn
                    small
                    color="blue-grey"
                    class="ml-2"
                    @click="getCopyTradeConfigAction"
                    :loading="loading"
                    ><v-icon>mdi-refresh</v-icon></v-btn
                >

                <v-btn
                    small
                    color="primary"
                    class="ml-2"
                    @click="toggleAdd"
                    :loading="loading"
                    >ADD</v-btn
                >
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-data-table
                        dense
                        :headers="copyTradeHeader"
                        :items="filteredCopyTradeConfig"
                        item-key="id"
                        class="elevation-0"
                        :items-per-page="15"
                        :hide-default-footer="false"
                        :header-props="{ sortIcon: null }"
                        fixed-header
                        :loading="loading"
                    >
                        <template v-slot:item="{ item }">
                            <tr>
                                <td column="id" class="fixCol_first">
                                    {{ item.id }}
                                </td>
                                <td column="server_id" class="fixCol_second">
                                    {{ getServerName(item.server_id) }}
                                </td>
                                <td column="SourceLogin" class="fixCol_third">
                                    {{ item.SourceLogin }}
                                </td>
                                <td column="TargetLogin" class="fixCol_forth">
                                    {{ item.TargetLogin }}
                                </td>

                                <td column="VerifyTimeRange">
                                    {{ item.VerifyTimeRange }}
                                </td>
                                <td column="PartialFollowerTimeRange">
                                    {{ item.PartialFollowerTimeRange }}
                                </td>
                                <td column="MobileRatio">
                                    {{ item.MobileRatio }}%
                                </td>
                                <td column="ClientRatio">
                                    {{ item.ClientRatio }}%
                                </td>
                                <td column="ExpertRatio">
                                    {{ item.ExpertRatio }}%
                                </td>
                                <td column="TP">{{ item.TP }}</td>
                                <td column="SL">{{ item.SL }}</td>
                                <td column="IsReverse">
                                    {{ item.IsReverse === 1 ? true : false }}
                                </td>
                                <td column="IsFollowPendingOrder">
                                    {{
                                        item.IsFollowPendingOrder === 1
                                            ? true
                                            : false
                                    }}
                                </td>
                                <td column="PendingRatios">
                                    {{ item.PendingRatios }}
                                </td>
                                <td column="CommentPrefix">
                                    {{ item.CommentPrefix }}
                                </td>
                                <td column="SymbolSuffix">
                                    {{ item.SymbolSuffix }}
                                </td>
                                <td column="MinLotsTrigger">
                                    {{
                                        item.MinLotsTrigger === 1 ? true : false
                                    }}
                                </td>
                                <td column="StopOrLimit">
                                    {{ item.StopOrLimit === 1 ? true : false }}
                                </td>
                                <td column="PendingPoints">
                                    {{ item.PendingPoints }}
                                </td>

                                <td column="actions" class="fixCol_last">
                                    <v-icon
                                        dense
                                        v-on:click="toggleUpdate(item)"
                                        :disabled="loading"
                                        >mdi-pen</v-icon
                                    >
                                    &nbsp;
                                    <v-icon
                                        dense
                                        :disabled="loading"
                                        @click="toggleRemoveDialog(item)"
                                        >mdi-delete</v-icon
                                    >
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog
            v-model="dialogRemove.visible"
            :fullscreen="dialogRemove.fullscreen"
            width="1200"
            :hide-overlay="true"
            :persistent="true"
            style="overflow-x: hidden"
        >
            <v-card class="pt-0">
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0">{{
                        dialogRemove.title
                    }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="white"
                        icon
                        @click="
                            dialogRemove.fullscreen = !dialogRemove.fullscreen
                        "
                        ><v-icon>mdi-arrow-expand</v-icon></v-btn
                    >
                    <v-btn
                        color="white"
                        icon
                        @click="dialogRemove.visible = false"
                        ><v-icon>mdi-close</v-icon></v-btn
                    >
                </v-app-bar>
                <v-row>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <v-data-table
                            dense
                            :headers="
                                copyTradeHeader.filter(
                                    (item) => item.value !== 'actions'
                                )
                            "
                            :items="removeData"
                            item-key="id"
                            class="elevation-0"
                            :loading="loading"
                            :items-per-page="-1"
                            id="home-table"
                            :hide-default-footer="true"
                            fixed-header
                            :header-props="{ sortIcon: null }"
                        >
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td column="id" class="fixCol_first">
                                        {{ item.id }}
                                    </td>
                                    <td
                                        column="server_id"
                                        class="fixCol_second"
                                    >
                                        {{ getServerName(item.server_id) }}
                                    </td>
                                    <td
                                        column="SourceLogin"
                                        class="fixCol_third"
                                    >
                                        {{ item.SourceLogin }}
                                    </td>
                                    <td
                                        column="TargetLogin"
                                        class="fixCol_forth"
                                    >
                                        {{ item.TargetLogin }}
                                    </td>
                                    <td column="VerifyTimeRange">
                                        {{ item.VerifyTimeRange }}
                                    </td>
                                    <td column="PartialFollowerTimeRange">
                                        {{ item.PartialFollowerTimeRange }}
                                    </td>
                                    <td column="MobileRatio">
                                        {{ item.MobileRatio }}%
                                    </td>
                                    <td column="ClientRatio">
                                        {{ item.ClientRatio }}%
                                    </td>
                                    <td column="ExpertRatio">
                                        {{ item.ExpertRatio }}%
                                    </td>
                                    <td column="TP">{{ item.TP }}</td>
                                    <td column="SL">{{ item.SL }}</td>
                                    <td column="IsReverse">
                                        {{
                                            item.IsReverse === 1 ? true : false
                                        }}
                                    </td>
                                    <td column="IsFollowPendingOrder">
                                        {{
                                            item.IsFollowPendingOrder === 1
                                                ? true
                                                : false
                                        }}
                                    </td>
                                    <td column="PendingRatios">
                                        {{ item.PendingRatios }}
                                    </td>
                                    <td column="CommentPrefix">
                                        {{ item.CommentPrefix }}
                                    </td>
                                    <td column="SymbolSuffix">
                                        {{ item.SymbolSuffix }}
                                    </td>
                                    <td column="MinLotsTrigger">
                                        {{
                                            item.MinLotsTrigger === 1
                                                ? true
                                                : false
                                        }}
                                    </td>
                                    <td column="StopOrLimit">
                                        {{
                                            item.StopOrLimit === 1
                                                ? true
                                                : false
                                        }}
                                    </td>
                                    <td column="PendingPoints">
                                        {{ item.PendingPoints }}
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="dialogRemove.visible = false"
                        :disabled="loading"
                        >Cancel</v-btn
                    >
                    <v-btn
                        color="red darken-1"
                        text
                        :loading="loading"
                        @click="removeCopyTrade"
                        ><span>Confirm</span></v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog.visible"
            :fullscreen="dialog.fullscreen"
            width="1000"
            :hide-overlay="true"
            :persistent="true"
        >
            <v-card class="pt-0">
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0">{{
                        dialog.title
                    }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="white"
                        icon
                        @click="dialog.fullscreen = !dialog.fullscreen"
                        ><v-icon>mdi-arrow-expand</v-icon></v-btn
                    >
                    <v-btn color="white" icon @click="dialog.visible = false"
                        ><v-icon>mdi-close</v-icon></v-btn
                    >
                </v-app-bar>

                <v-row class="ma-0">
                    <v-col
                        cols="3"
                        class="pt-0 pb-0"
                        v-if="dialog.title.includes('Update')"
                    >
                        <label
                            style="
                                display: flex;
                                align-items: end;
                                height: 100%;
                            "
                        >
                            ID
                        </label>
                    </v-col>
                    <v-col
                        cols="3"
                        class="pt-0 pb-0"
                        v-if="dialog.title.includes('Update')"
                    >
                        <v-text-field
                            v-model="dialog.id"
                            readonly
                            required
                            single-line
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                        <label
                            style="
                                display: flex;
                                align-items: end;
                                height: 100%;
                            "
                        >
                            Server
                        </label>
                    </v-col>
                    <v-col
                        :cols="dialog.title.includes('Update') ? 3 : 9"
                        class="pt-0 pb-0"
                    >
                        <v-autocomplete
                            v-model="dialog.server_id"
                            :items="serverData"
                            item-text="server_name"
                            item-value="id"
                            chips
                            required
                            small-chips
                            single-line
                            hide-details="auto"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                        <label
                            style="
                                display: flex;
                                align-items: end;
                                height: 100%;
                            "
                        >
                            Source Login
                        </label>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                        <v-autocomplete
                            v-model="dialog.SourceLogin"
                            :rules="[(v) => !!v || 'input is required']"
                            :items="
                                options.filter_login.filter(
                                    (data) =>
                                        data.server_id === dialog.server_id
                                )
                            "
                            item-text="name"
                            item-value="name"
                            chips
                            required
                            small-chips
                            single-line
                            hide-details="auto"
                        >
                        </v-autocomplete>
                    </v-col>

                    <v-col cols="3" class="pt-0 pb-0">
                        <label
                            style="
                                display: flex;
                                align-items: end;
                                height: 100%;
                            "
                        >
                            Target Login
                        </label>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                        <v-autocomplete
                            v-model="dialog.TargetLogin"
                            :rules="[(v) => !!v || 'input is required']"
                            :items="
                                options.filter_login.filter(
                                    (data) =>
                                        data.server_id === dialog.server_id
                                )
                            "
                            item-text="name"
                            item-value="name"
                            chips
                            required
                            small-chips
                            single-line
                            hide-details="auto"
                        >
                        </v-autocomplete>
                    </v-col>

                    <v-col cols="3" class="pt-0 pb-0">
                        <label
                            style="
                                display: flex;
                                align-items: end;
                                height: 100%;
                            "
                        >
                            Verify Time Range
                        </label>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                        <v-text-field
                            v-model="dialog.VerifyTimeRange"
                            :rules="[
                                (v) => !!v || 'input is required',
                                rules.int,
                            ]"
                            required
                            single-line
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="3" class="pt-0 pb-0">
                        <label
                            style="
                                display: flex;
                                align-items: end;
                                height: 100%;
                            "
                        >
                            Partial Follower Time Range
                        </label>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                        <v-text-field
                            v-model="dialog.PartialFollowerTimeRange"
                            :rules="[
                                (v) => !!v || 'input is required',
                                rules.int,
                            ]"
                            required
                            single-line
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="3" class="pt-0 pb-0">
                        <label
                            style="
                                display: flex;
                                align-items: end;
                                height: 100%;
                            "
                        >
                            Mobile Ratio
                        </label>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                        <v-text-field
                            v-model="dialog.MobileRatio"
                            :rules="[
                                (v) => !!v || 'input is required',
                                rules.int,
                            ]"
                            required
                            single-line
                            hide-details="auto"
                            ><v-icon slot="append">
                                mdi-percent-outline
                            </v-icon></v-text-field
                        >
                    </v-col>

                    <v-col cols="3" class="pt-0 pb-0">
                        <label
                            style="
                                display: flex;
                                align-items: end;
                                height: 100%;
                            "
                        >
                            Client Ratio
                        </label>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                        <v-text-field
                            v-model="dialog.ClientRatio"
                            :rules="[
                                (v) => !!v || 'input is required',
                                rules.int,
                            ]"
                            required
                            single-line
                            hide-details="auto"
                            ><v-icon slot="append">
                                mdi-percent-outline
                            </v-icon></v-text-field
                        >
                    </v-col>

                    <v-col cols="3" class="pt-0 pb-0">
                        <label
                            style="
                                display: flex;
                                align-items: end;
                                height: 100%;
                            "
                        >
                            Expert Ratio
                        </label>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                        <v-text-field
                            v-model="dialog.ExpertRatio"
                            :rules="[
                                (v) => !!v || 'input is required',
                                rules.int,
                            ]"
                            required
                            single-line
                            hide-details="auto"
                            ><v-icon slot="append">
                                mdi-percent-outline
                            </v-icon></v-text-field
                        >
                    </v-col>
                    <v-col cols="6" class="pt-0 pb-0"></v-col>

                    <v-col cols="3" class="pt-0 pb-0">
                        <label
                            style="
                                display: flex;
                                align-items: end;
                                height: 100%;
                            "
                        >
                            Take Profit
                        </label>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                        <v-text-field
                            v-model="dialog.TP"
                            :rules="[
                                (v) => v !== null || 'input is required',
                                rules.number,
                            ]"
                            required
                            single-line
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="3" class="pt-0 pb-0">
                        <label
                            style="
                                display: flex;
                                align-items: end;
                                height: 100%;
                            "
                        >
                            Stop Loss
                        </label>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                        <v-text-field
                            v-model="dialog.SL"
                            :rules="[
                                (v) => v !== null || 'input is required',
                                rules.number,
                            ]"
                            required
                            single-line
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="3" class="pt-0 pb-0">
                        <label
                            style="
                                display: flex;
                                align-items: end;
                                height: 100%;
                            "
                        >
                            Is Reversed
                        </label>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                        <v-switch
                            v-model="dialog.IsReverse"
                            hide-details="auto"
                        ></v-switch>
                    </v-col>

                    <v-col cols="3" class="pt-0 pb-0">
                        <label
                            style="
                                display: flex;
                                align-items: end;
                                height: 100%;
                            "
                        >
                            Is Following Pending Order
                        </label>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                        <v-switch
                            v-model="dialog.IsFollowPendingOrder"
                            hide-details="auto"
                            style="margin-top: 13px"
                        ></v-switch>
                    </v-col>

                    <v-col cols="3" class="pt-0 pb-0">
                        <label
                            style="
                                display: flex;
                                align-items: end;
                                height: 100%;
                            "
                        >
                            Min Lots Trigger
                        </label>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                        <v-switch
                            v-model="dialog.MinLotsTrigger"
                            hide-details="auto"
                            style="margin-top: 13px"
                        ></v-switch>
                    </v-col>

                    <v-col cols="3" class="pt-0 pb-0">
                        <label
                            style="
                                display: flex;
                                align-items: end;
                                height: 100%;
                            "
                        >
                            Stop Or Limit
                        </label>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                        <v-switch
                            v-model="dialog.StopOrLimit"
                            hide-details="auto"
                            style="margin-top: 13px"
                        ></v-switch>
                    </v-col>

                    <v-col cols="3" class="pt-0 pb-0">
                        <label
                            style="
                                display: flex;
                                align-items: end;
                                height: 100%;
                            "
                        >
                            Comment Prefix
                        </label>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                        <v-text-field
                            v-model="dialog.CommentPrefix"
                            :rules="[(v) => !!v || 'input is required']"
                            required
                            single-line
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="3" class="pt-0 pb-0">
                        <label
                            style="
                                display: flex;
                                align-items: end;
                                height: 100%;
                            "
                        >
                            Symbol Suffix
                        </label>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                        <v-text-field
                            v-model="dialog.SymbolSuffix"
                            :rules="[(v) => !!v || 'input is required']"
                            required
                            single-line
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="3" class="pt-0 pb-0">
                        <label
                            style="
                                display: flex;
                                align-items: end;
                                height: 100%;
                            "
                        >
                            Pending Ratios
                        </label>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                        <v-text-field
                            v-model="dialog.PendingRatios"
                            :rules="[
                                (v) => !!v || 'input is required',
                                rules.pendingRatioOrPoint,
                            ]"
                            required
                            single-line
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                        <label
                            style="
                                display: flex;
                                align-items: end;
                                height: 100%;
                            "
                        >
                            Pending Points
                        </label>
                    </v-col>
                    <v-col cols="3" class="pt-0 pb-0">
                        <v-text-field
                            v-model="dialog.PendingPoints"
                            :rules="[
                                (v) => !!v || 'input is required',
                                rules.pendingRatioOrPoint,
                                rules.patternMatch(
                                    dialog.PendingPoints,
                                    dialog.PendingRatios
                                ),
                            ]"
                            required
                            single-line
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="dialog.visible = false"
                        :disabled="dialog.loading"
                        >Cancel</v-btn
                    >

                    <v-btn
                        color="green darken-1"
                        text
                        @click="addConfig"
                        :loading="loading"
                        v-show="dialog.title.includes('Add')"
                        ><span>Add</span></v-btn
                    >
                    <v-btn
                        color="green darken-1"
                        text
                        @click="updateConfig"
                        :loading="loading"
                        v-show="dialog.title.includes('Update')"
                        ><span>Update</span></v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <UpdateConfirmation
            type="copy_trade"
            :addition="addition"
            :edition="edition"
            :header="copyTradeHeaderClean"
        />
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import validator from "@assets/js/validator";
import { snackbar } from "@components/mixins/snackbar";
import UpdateConfirmation from "./ComponentUpdateConfirmation.vue";
import deepClone from "deep-clone";
import { rules } from "@assets/js/validator";

export default {
    mixins: [snackbar],
    components: { UpdateConfirmation },
    data() {
        return {
            copyTradeSearch: "",
            dialog: {
                visible: false,
                fullscreen: false,
                title: "",
            },
            confirmPopover: false,
            selectedDeletion: {},
            addition: [],
            edition: [],
            dialogRemove: {
                title: "Are you sure you want to remove this record?",
                fullscreen: false,
                visible: false,
            },
            removeData: [],
            selectedServer: null,
            selectedServerName: null,
            loginOptions: [],
            rules: rules,
        };
    },
    watch: {
        updated(nv) {
            if (nv) {
                this.dialog.visible = false;
                this.confirmPopover = false;
                this.getCopyTradeConfigAction();
            }
        },
        loading(nv, ov) {
            if (!nv) {
                this.dialogRemove.visible = false;
                this.dialog.visible = false;
            }
        },
    },
    computed: {
        ...mapState("SettingsCopyTrade", [
            "copyTradeConfig",
            "copyTradeHeader",
            "loading",
            "updated",
            "csvFields",
        ]),
        ...mapState("SettingsServer", ["serverData"]),
        ...mapState("Config", ["options"]),
        copyTradeHeaderClean() {
            const header = deepClone(this.copyTradeHeader).filter(
                (item) =>
                    item.value !== "updated_at" &&
                    item.value !== "created_at" &&
                    item.value !== "actions"
            );
            return header;
        },
        filteredCopyTradeConfig() {
            return this.copyTradeConfig.filter(
                (item) =>
                    (item.SourceLogin.toString().includes(
                        this.copyTradeSearch
                    ) ||
                        item.TargetLogin.toString().includes(
                            this.copyTradeSearch
                        )) &&
                    item.server_id === this.selectedServer
            );
        },
    },
    methods: {
        ...mapActions("SettingsCopyTrade", [
            "getCopyTradeConfigAction",
            "addCopyTradeConfigAction",
            "updateCopyTradeConfigAction",
            "deleteCopyTradeConfigAction",
        ]),
        ...mapMutations("SettingsCopyTrade", ["UPDATE_STATUS"]),

        changeServer(data) {
            this.selectedServer = data.id;
            this.selectedServerName = data.server_name;
        },
        toggleAdd() {
            this.dialog = {
                visible: true,
                fullscreen: false,
                title: "Add New Copy Trade Config",
                SourceLogin: "",
                TargetLogin: "",
                server_id: this.selectedServer,
                VerifyTimeRange: "",
                PartialFollowerTimeRange: "",
                MobileRatio: "",
                ClientRatio: "",
                ExpertRatio: "",
                TP: "",
                SL: "",
                IsReverse: false,
                IsFollowPendingOrder: false,
                PendingRatios: "",
                CommentPrefix: "",
                SymbolSuffix: "",
                MinLotsTrigger: false,
                StopOrLimit: false,
                PendingPoints: "",
            };
            this.UPDATE_STATUS(false);
        },
        toggleUpdate(data) {
            this.dialog = {
                id: data.id,
                visible: true,
                fullscreen: false,
                title: "Update Copy Trade Config",
                server_id: data.server_id,
                SourceLogin: data.SourceLogin.toString(),
                TargetLogin: data.TargetLogin.toString(),
                VerifyTimeRange: data.VerifyTimeRange,
                PartialFollowerTimeRange: data.PartialFollowerTimeRange,
                MobileRatio: data.MobileRatio,
                ClientRatio: data.ClientRatio,
                ExpertRatio: data.ExpertRatio,
                TP: data.TP,
                SL: data.SL,
                IsReverse: data.IsReverse,
                IsFollowPendingOrder: data.IsFollowPendingOrder,
                PendingRatios: data.PendingRatios,
                CommentPrefix: data.CommentPrefix,
                SymbolSuffix: data.SymbolSuffix,
                MinLotsTrigger: data.MinLotsTrigger,
                StopOrLimit: data.StopOrLimit,
                PendingPoints: data.PendingPoints,
            };
            this.UPDATE_STATUS(false);
        },
        toggleRemoveDialog(data) {
            if (this.changed) {
                this.removeWarning = true;
            } else {
                this.dialogRemove.visible = true;
                this.removeData = [data];
            }
        },
        addConfig() {
            if (
                validator.isAnyFieldEmpty([
                    this.dialog.SourceLogin,
                    this.dialog.TargetLogin,
                    this.dialog.server_id,
                    this.dialog.VerifyTimeRange,
                    this.dialog.PartialFollowerTimeRange,
                    this.dialog.MobileRatio,
                    this.dialog.ClientRatio,
                    this.dialog.ExpertRatio,
                    this.dialog.TP,
                    this.dialog.SL,
                    this.dialog.IsReverse,
                    this.dialog.IsFollowPendingOrder,
                    this.dialog.PendingRatios,
                    this.dialog.CommentPrefix,
                    this.dialog.SymbolSuffix,
                    this.dialog.MinLotsTrigger,
                    this.dialog.StopOrLimit,
                    this.dialog.PendingPoints,
                ])
            ) {
                this.snackBarDanger(
                    "Please fill all of the input fields before adding new book"
                );
                return;
            }
            const params = {
                SourceLogin: parseInt(this.dialog.SourceLogin),
                TargetLogin: parseInt(this.dialog.TargetLogin),
                server_id: this.dialog.server_id,
                VerifyTimeRange: this.dialog.VerifyTimeRange,
                PartialFollowerTimeRange: this.dialog.PartialFollowerTimeRange,
                MobileRatio: this.dialog.MobileRatio,
                ClientRatio: this.dialog.ClientRatio,
                ExpertRatio: this.dialog.ExpertRatio,
                TP: this.dialog.TP,
                SL: this.dialog.SL,
                IsReverse: this.dialog.IsReverse ? 1 : 0,
                IsFollowPendingOrder: this.dialog.IsFollowPendingOrder ? 1 : 0,
                PendingRatios: this.dialog.PendingRatios,
                CommentPrefix: this.dialog.CommentPrefix,
                SymbolSuffix: this.dialog.SymbolSuffix,
                MinLotsTrigger: this.dialog.MinLotsTrigger ? 1 : 0,
                StopOrLimit: this.dialog.StopOrLimit ? 1 : 0,
                PendingPoints: this.dialog.PendingPoints,
            };
            this.addCopyTradeConfigAction(params);
        },
        updateConfig() {
            if (
                validator.isAnyFieldEmpty([
                    this.dialog.SourceLogin,
                    this.dialog.TargetLogin,
                    this.dialog.server_id,
                    this.dialog.VerifyTimeRange,
                    this.dialog.PartialFollowerTimeRange,
                    this.dialog.MobileRatio,
                    this.dialog.ClientRatio,
                    this.dialog.ExpertRatio,
                    this.dialog.TP,
                    this.dialog.SL,
                    this.dialog.IsReverse,
                    this.dialog.IsFollowPendingOrder,
                    this.dialog.PendingRatios,
                    this.dialog.CommentPrefix,
                    this.dialog.SymbolSuffix,
                    this.dialog.MinLotsTrigger,
                    this.dialog.StopOrLimit,
                    this.dialog.PendingPoints,
                ])
            ) {
                this.snackBarDanger(
                    "Please fill all of the input fields before adding new book"
                );
                return;
            }
            const params = {
                id: this.dialog.id,
                SourceLogin: parseInt(this.dialog.SourceLogin),
                TargetLogin: parseInt(this.dialog.TargetLogin),
                server_id: this.dialog.server_id,
                VerifyTimeRange: this.dialog.VerifyTimeRange,
                PartialFollowerTimeRange: this.dialog.PartialFollowerTimeRange,
                MobileRatio: this.dialog.MobileRatio,
                ClientRatio: this.dialog.ClientRatio,
                ExpertRatio: this.dialog.ExpertRatio,
                TP: this.dialog.TP,
                SL: this.dialog.SL,
                IsReverse: this.dialog.IsReverse ? 1 : 0,
                IsFollowPendingOrder: this.dialog.IsFollowPendingOrder ? 1 : 0,
                PendingRatios: this.dialog.PendingRatios,
                CommentPrefix: this.dialog.CommentPrefix,
                SymbolSuffix: this.dialog.SymbolSuffix,
                MinLotsTrigger: this.dialog.MinLotsTrigger ? 1 : 0,
                StopOrLimit: this.dialog.StopOrLimit ? 1 : 0,
                PendingPoints: this.dialog.PendingPoints,
            };
            this.updateCopyTradeConfigAction(params);
        },
        removeCopyTrade() {
            this.deleteCopyTradeConfigAction({ id: this.removeData[0].id });
        },
        readFile(fileInput) {
            var reader = new FileReader();
            reader.onload = () => {
                var csv = reader.result
                    .replaceAll('"""', "")
                    .replaceAll('"=""', "");
                var lines = csv.split("\r\n");
                var result = [];
                var headers = lines[0].split(",");
                if (
                    JSON.stringify(headers) ===
                    JSON.stringify(Object.keys(this.csvFields))
                ) {
                    for (var i = 1; i < lines.length; i++) {
                        if (lines[i] === "") {
                            break;
                        }
                        var obj = {};
                        var currentLine = lines[i].split(",");
                        for (var j = 0; j < headers.length; j++) {
                            obj[this.csvFields[headers[j]]] = currentLine[j];
                        }
                        result.push(obj);
                    }

                    this.addition = [];
                    this.edition = [];
                    let add = [];
                    let change = [];
                    result.map((item) => {
                        const found = this.copyTradeConfig.find((data) => {
                            return data.id.toString() === item.id;
                        });
                        if (found) {
                            for (const attr in item) {
                                if (item[attr] !== found[attr].toString()) {
                                    change.push(item);
                                    break;
                                }
                            }
                        } else {
                            add.push(item);
                        }
                    });
                    if (change.length === 0 && add.length === 0) {
                        this.snackBarSuccess("All settings are up to date.");
                    } else {
                        this.addition = [...add];
                        this.edition = [...change];
                    }
                } else {
                    this.snackBarDanger("Input file is invalid.");
                }
            };
            reader.readAsText(fileInput);
        },
        resetFileInput() {
            this.$refs.bookFile.value = "";
            this.$refs.bookFile.click();
        },
        getServerName(serverID) {
            return (
                this.serverData.find((data) => data.id === serverID)
                    ?.server_name || "N/A"
            );
        },
    },
    mounted() {
        this.selectedServer = this.serverData[0]?.id;
        this.selectedServerName = this.serverData[0]?.server_name;
    },
};
</script>
<style>
.fixCol_last.header {
    z-index: 4 !important;
}
.fixCol_last {
    position: sticky !important;
    right: 0;
    z-index: 1 !important;
    background-color: #1e1e1e;
}

.fixCol_first.header,
.fixCol_second.header,
.fixCol_third.header,
.fixCol_forth.header {
    z-index: 4 !important;
}
.fixCol_first {
    position: sticky !important;
    left: 0;
    z-index: 1 !important;
    background-color: #1e1e1e;
}
.fixCol_second {
    position: sticky !important;
    left: 60px;
    z-index: 1 !important;
    background-color: #1e1e1e;
}
.fixCol_third {
    position: sticky !important;
    left: 160px;
    z-index: 1 !important;
    background-color: #1e1e1e;
}
.fixCol_forth {
    position: sticky !important;
    left: 280px;
    z-index: 1 !important;
    background-color: #1e1e1e;
}
</style>
<style scoped>
div >>> .v-dialog {
    overflow-x: hidden;
}
</style>
