var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-tabs',_vm._l((_vm.tabNames),function(tabName){return _c('v-tab',{key:tabName,attrs:{"id":tabName},on:{"click":function($event){return _vm.changeTab(tabName)}},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_vm._v(_vm._s(tabName == "Large Volume" ? "Big Lot" : tabName))])}),1)],1),_c('v-col',{staticStyle:{"display":"flex"},attrs:{"cols":"2"}},[_c('v-row',{attrs:{"justify":"end","align":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue-grey","loading":_vm.loading,"small":""},on:{"click":_vm.resetFileInput}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","dense":""}},[_vm._v(" mdi-file-upload ")]),_c('input',{ref:"alertFile",staticStyle:{"display":"none"},attrs:{"type":"file","accept":".csv"},on:{"change":function (event) { return _vm.readFile(event.target.files[0]); }}})],1)]}}])},[_c('span',[_vm._v("Upload settings from CSV")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"blue-grey","loading":_vm.loading,"small":""}},'v-btn',attrs,false),on),[_c('download-excel',{staticStyle:{"display":"inline"},attrs:{"data":_vm.configData,"fields":_vm.csvFields[_vm.selectedTab],"type":"csv","name":_vm.selectedTab.replaceAll(' ', '') +
                                    'Settings.csv'}},[_c('v-icon',{attrs:{"dark":"","dense":""}},[_vm._v(" mdi-microsoft-excel ")])],1)],1)]}}])},[_c('span',[_vm._v("Export settings as CSV")])]),_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"small":"","color":"blue-grey","loading":_vm.loading},on:{"click":_vm.forceReload}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),(_vm.selectedTab === 'Tick')?_c('TickStepper',{attrs:{"isDisabled":!_vm.allowEditing}}):_c('EditForm',{attrs:{"type":_vm.selectedTab,"dataHeader":_vm.addHeader,"isEditing":false,"isDisabled":!_vm.allowEditing}})],1)],1)],1),_c('ConfigTable',{attrs:{"selectedType":_vm.selectedTab,"isDisabled":!_vm.allowEditing}}),_c('UpdateConfirmation',{attrs:{"type":_vm.selectedTab,"addition":_vm.addition,"edition":_vm.edition,"header":_vm.updateHeader}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }