<template>
    <div>
        <v-row>
            <v-col cols="6">
                <v-text-field
                    class=""
                    v-model="brokerSearch"
                    outlined
                    dense
                    label="Search Broker"
                    single-line
                    hide-details
                >
                </v-text-field>
            </v-col>

            <v-col cols="2"></v-col>

            <v-col cols="4" class="text-right">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="blue-grey"
                            :loading="loading"
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="resetFileInput"
                        >
                            <v-icon dark dense> mdi-file-upload </v-icon>
                            <input
                                type="file"
                                ref="brokerFile"
                                @change="
                                    (event) => readFile(event.target.files[0])
                                "
                                accept=".csv"
                                style="display: none"
                            />
                        </v-btn>
                    </template>
                    <span>Upload settings from CSV</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="blue-grey"
                            class="ml-2"
                            :loading="loading"
                            small
                            v-bind="attrs"
                            v-on="on"
                        >
                            <download-excel
                                type="csv"
                                :data="brokerData"
                                :fields="csvFields"
                                style="display: inline"
                                name="BrokerSettings.csv"
                            >
                                <v-icon dark dense>
                                    mdi-microsoft-excel
                                </v-icon>
                            </download-excel>
                        </v-btn>
                    </template>
                    <span>Export settings as CSV</span>
                </v-tooltip>

                <v-btn
                    color="blue-grey"
                    class="ml-2"
                    @click="getBrokerAction"
                    :loading="loading"
                    small
                    ><v-icon>mdi-refresh</v-icon></v-btn
                >

                <v-btn
                    color="primary"
                    class="ml-2"
                    v-on:click="toggleDialog('add')"
                    :loading="loading"
                    small
                    >ADD</v-btn
                >
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-data-table
                    dense
                    :search="brokerSearch"
                    :headers="brokerHeader"
                    :items="brokerData"
                    :loading="loading"
                    item-key="id"
                    class="elevation-0"
                    :items-per-page="10"
                    id="home-table"
                    :hide-default-footer="false"
                    :header-props="{ sortIcon: null }"
                    fixed-header
                >
                    <template v-slot:item.action="{ item }">
                        <v-icon
                            dense
                            v-on:click="toggleDialog('update', item)"
                            :disabled="loading"
                            >mdi-pen</v-icon
                        >
                        &nbsp;
                        <v-icon
                            dense
                            @click="deleteConfirmation(item)"
                            :disabled="loading"
                            >mdi-delete</v-icon
                        >
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-dialog
            v-model="dialog.visible"
            :fullscreen="dialog.fullscreen"
            width="500"
            :hide-overlay="true"
            :persistent="true"
        >
            <v-card class="pt-0">
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0">{{
                        dialog.title
                    }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="white"
                        icon
                        @click="dialog.fullscreen = !dialog.fullscreen"
                        ><v-icon>mdi-arrow-expand</v-icon></v-btn
                    >
                    <v-btn color="white" icon @click="dialog.visible = false"
                        ><v-icon>mdi-close</v-icon></v-btn
                    >
                </v-app-bar>

                <v-card-actions>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-text-field
                                label="Broker Name"
                                v-model="dialog.broker_name"
                                hide-details="auto"
                                :rules="[rules.required]"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-text-field
                                label="Description"
                                v-model="dialog.description"
                                hide-details="auto"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-actions>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="dialog.visible = false"
                        :disabled="dialog.loading"
                        >Cancel</v-btn
                    >
                    <v-btn
                        color="green darken-1"
                        text
                        @click="brokerCreate"
                        v-if="dialog.type == 'add'"
                        :loading="loading"
                        ><span>Add</span></v-btn
                    >
                    <v-btn
                        color="green darken-1"
                        text
                        @click="brokerUpdate"
                        v-if="dialog.type == 'update'"
                        :loading="loading"
                        ><span>Update</span></v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <DeleteConfirmDialog
            :confirmPopover="confirmPopover"
            type="Broker"
            :name="selectedDeletion.broker_name"
            :loading="loading"
            @close="
                () => {
                    confirmPopover = false;
                }
            "
            @confirm="deleteBroker"
        ></DeleteConfirmDialog>
        <UpdateConfirmation
            type="broker"
            :addition="addition"
            :edition="edition"
            :header="brokerHeader"
        />
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { snackbar } from "@components/mixins/snackbar";
import DeleteConfirmDialog from "@components/ComponentDeleteConfirmDialog";
import { rules } from "@assets/js/validator";
import validator from "@assets/js/validator";
import UpdateConfirmation from "./ComponentUpdateConfirmation.vue";
export default {
    mixins: [snackbar],
    components: { DeleteConfirmDialog, UpdateConfirmation },
    data() {
        return {
            brokerSearch: "",
            dialog: {
                type: "add", // type add, update broker
                visible: false,
                fullscreen: false,
                title: "Add New Broker",
                broker_name: "",
                description: "",
                id: -1,
            },
            selectedDeletion: {},
            confirmPopover: false,
            rules: rules,
            fileInput: null,
            addition: [],
            edition: [],
        };
    },
    computed: {
        ...mapState("SettingsBroker", [
            "brokerData",
            "brokerHeader",
            "loading",
            "updated",
            "returnMsg",
            "csvFields",
        ]),
    },
    watch: {
        updated(nv) {
            if (nv) {
                this.dialog.visible = false;
                this.confirmPopover = false;
                this.snackBarSuccess(this.returnMsg);
                this.getBrokerAction();
            }
        },
    },
    methods: {
        ...mapActions("SettingsBroker", [
            "getBrokerAction",
            "postBrokerAction",
            "putBrokerAction",
            "deleteBrokerAction",
        ]),
        /**
         * Toggle dialog to add book
         */
        toggleDialog(type, data = null) {
            this.dialog.visible = true;
            this.dialog.type = type;

            if (type === "add") {
                this.dialog.title = "Add New Broker";
                this.dialog.broker_name = null;
                this.dialog.description = null;
                this.dialog.id = -1;
            } else {
                this.dialog.title = "Update Broker";
                this.dialog.broker_name = data.broker_name;
                this.dialog.description = data.description;
                this.dialog.id = data.id;
            }
        },
        /**
         * Update broker detail
         * @return  {[type]}  [return description]
         */
        brokerUpdate() {
            if (validator.isAnyFieldEmpty([this.dialog.broker_name])) {
                this.snackBarDanger("Broker name cannot be empty.");
                return;
            }
            let params = {
                id: this.dialog.id,
                broker_name: this.dialog.broker_name,
                description: this.dialog.description,
            };
            this.putBrokerAction(params);
        },
        /**
         * Create new broker
         * @return  {[type]}  [return description]
         */
        brokerCreate() {
            if (validator.isAnyFieldEmpty([this.dialog.broker_name])) {
                this.snackBarDanger("Broker name cannot be empty.");
                return;
            }
            let params = {
                broker_name: this.dialog.broker_name,
                description: this.dialog.description,
            };
            this.postBrokerAction(params);
        },
        deleteConfirmation(brokerInfo) {
            this.selectedDeletion = brokerInfo;
            this.confirmPopover = true;
        },
        deleteBroker() {
            this.deleteBrokerAction({ id: this.selectedDeletion.id });
        },

        readFile(fileInput) {
            var reader = new FileReader();
            reader.onload = () => {
                var csv = reader.result
                    .replaceAll('"""', "")
                    .replaceAll('"=""', "");
                var lines = csv.split("\r\n");
                var result = [];
                var headers = lines[0].split(",");
                if (
                    JSON.stringify(headers) ===
                    JSON.stringify(Object.keys(this.csvFields))
                ) {
                    for (var i = 1; i < lines.length; i++) {
                        if (lines[i] === "") {
                            break;
                        }
                        var obj = {};
                        var currentLine = lines[i].split(",");
                        for (var j = 0; j < headers.length; j++) {
                            obj[this.csvFields[headers[j]]] = currentLine[j];
                        }
                        result.push(obj);
                    }

                    this.addition = [];
                    this.edition = [];
                    let add = [];
                    let change = [];
                    result.map((item) => {
                        const found = this.brokerData.find(
                            (data) => data.id.toString() === item.id
                        );
                        if (found) {
                            for (const attr in item) {
                                if (item[attr] !== found[attr].toString()) {
                                    change.push(item);
                                    break;
                                }
                            }
                        } else {
                            add.push(item);
                        }
                    });
                    if (change.length === 0 && add.length === 0) {
                        this.snackBarSuccess("All settings are up to date.");
                    } else {
                        this.addition = [...add];
                        this.edition = [...change];
                    }
                } else {
                    this.snackBarDanger("Input file is invalid.");
                }
            };
            reader.readAsText(fileInput);
        },
        resetFileInput() {
            this.$refs.brokerFile.value = "";
            this.$refs.brokerFile.click();
        },
    },
};
</script>
