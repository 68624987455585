import { render, staticRenderFns } from "./ComponentCopyTrade.vue?vue&type=template&id=94828966&scoped=true&"
import script from "./ComponentCopyTrade.vue?vue&type=script&lang=js&"
export * from "./ComponentCopyTrade.vue?vue&type=script&lang=js&"
import style0 from "./ComponentCopyTrade.vue?vue&type=style&index=0&lang=css&"
import style1 from "./ComponentCopyTrade.vue?vue&type=style&index=1&id=94828966&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94828966",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAppBar,VAutocomplete,VBtn,VCard,VCardActions,VCol,VDataTable,VDialog,VIcon,VList,VListItem,VListItemTitle,VMenu,VRow,VSpacer,VSwitch,VTextField,VToolbarTitle,VTooltip})
