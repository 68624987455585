<template>
    <v-dialog
        v-model="visible"
        :fullscreen="fullscreen"
        width="1000"
        :hide-overlay="true"
        :persistent="true"
    >
        <v-card class="pt-0">
            <v-app-bar
                flat
                color="rgba(0, 0, 0, 0)"
                style="background: rgb(26, 57, 88)"
            >
                <v-toolbar-title class="text-h6 white--text pl-0">
                    ORDER ROUTING MAPPING
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="white" icon @click="fullscreen = !fullscreen">
                    <v-icon v-if="!fullscreen">mdi-arrow-expand</v-icon>
                    <v-icon v-else>mdi-arrow-collapse</v-icon>
                </v-btn>
                <v-btn
                    color="white"
                    icon
                    @click="$emit('update:visible', false)"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-app-bar>

            <v-card-actions>
                <v-data-table
                    dense
                    :headers="header"
                    :header-props="{ sortIcon: null }"
                    :items="data"
                    item-key="id"
                    :items-per-page="-1"
                    class="elevation-0"
                    id="home-table"
                    hide-default-footer
                    fixed-header
                    :height="tableHeight"
                    style="width: 100%"
                >
                </v-data-table>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            tyoe: Boolean,
            default: false,
        },
        data: { type: Array, default: () => [] },
    },
    data() {
        return {
            fullscreen: true,
            tableHeight: undefined,
            cacheTableHeight: undefined,
            header: [
                { text: "PK", value: "pk", align: "left" },
                { text: "Order", value: "_order", align: "left" },
                { text: "Id", value: "id", align: "left" },
                {
                    text: "Order Routing Profile Name",
                    value: "order_routing_profile_name",
                    align: "left",
                },
                {
                    text: "Taker Entity Id",
                    value: "taker_entity_id",
                    align: "left",
                },
                {
                    text: "Taker Connection Id Filter",
                    value: "taker_connection_id_filter",
                    align: "left",
                },
                {
                    text: "Taker Login Filter",
                    value: "taker_login_filter",
                    align: "left",
                },
                {
                    text: "Taker Group Filter",
                    value: "taker_group_filter",
                    align: "left",
                },
                {
                    text: "Core Symbol Filter",
                    value: "core_symbol_filter",
                    align: "left",
                },
                {
                    text: "Symbol Group Filter",
                    value: "symbol_group_filter",
                    align: "left",
                },
                {
                    text: "Taker Input Execution Method Filter",
                    value: "taker_input_execution_method_filter",
                    align: "left",
                },
                {
                    text: "Taker Input Time In Force Filter",
                    value: "taker_input_time_in_force_filter",
                    align: "left",
                },
                {
                    text: "Taker Mt Order Type Restriction",
                    value: "taker_mt_order_type_restriction",
                    align: "left",
                },
                {
                    text: "Created At",
                    value: "created_at",
                    align: "left",
                },
                {
                    text: "Updated At",
                    value: "updated_at",
                    align: "left",
                },
            ],
        };
    },
    watch: {
        fullscreen(nv) {
            if (nv) {
                this.tableHeight = window.innerHeight - 90;
            } else {
                if ((this.data.length + 1) * 32 > 500) {
                    this.tableHeight = 500;
                } else {
                    this.tableHeight = undefined;
                }
            }
        },
    },
};
</script>

<style></style>
