<template>
    <div>
        <v-row justify="end" align="center" class="mt-2">
            <v-btn
                small
                color="blue-grey"
                class="mr-2 ml-2"
                @click="forceReload"
                :loading="loading"
                ><v-icon>mdi-refresh</v-icon></v-btn
            >
            <v-btn
                small
                color="primary"
                class="mr-2 ml-2"
                :loading="loading"
                @click="uploadConfig"
                >UPDATE</v-btn
            >
        </v-row>
        <v-row class="ma-2">
            <v-col cols="2" v-for="item in lpConfigForm">
                <v-switch
                    :key="item.id"
                    v-model="item.active"
                    :label="item.name.toUpperCase()"
                    hide-details
                >
                    {{ item.id }}</v-switch
                >
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { snackbar } from "@components/mixins/snackbar";
import { permission } from "@components/mixins/permission";
import deepClone from "deep-clone";

export default {
    mixins: [permission, snackbar],
    data() {
        return { lpConfigForm: [] };
    },
    computed: {
        ...mapState("LP", ["lpList", "loading"]),
        lpConfig() {
            const config = deepClone(this.lpList).filter(
                (item) => item.id !== -1
            );
            return config;
        },
    },
    watch: {
        lpConfig(nv) {
            if (nv.length) {
                nv.map((item) => {
                    this.lpConfigForm.push({
                        id: item.id,
                        name: item.lp_name,
                        active: item.active === 1,
                    });
                });
            } else {
                this.lpConfigForm = [];
            }
        },
    },
    methods: {
        ...mapActions("LP", ["getLPInfoAction", "updateLpInfoAction"]),
        ...mapMutations("LP", ["UPDATE_LP_LIST"]),

        forceReload() {
            this.UPDATE_LP_LIST([]);
            this.getLPInfoAction();
        },
        uploadConfig() {
            const params = { batchUpdate: true, changes: [] };
            this.lpConfigForm.map((item) => {
                if (
                    !(
                        (this.lpConfig.find((i) => i.id == item.id)?.active ===
                            1 &&
                            item.active) ||
                        (this.lpConfig.find((i) => i.id == item.id)?.active ===
                            0 &&
                            item.active === false)
                    )
                ) {
                    params.changes.push({
                        id: item.id,
                        active: item.active ? 1 : 0,
                    });
                }
            });
            if (params.changes.length > 0) {
                this.updateLpInfoAction(params);
            } else {
                this.snackBarDanger("No changes detected, update aborted");
            }
        },
    },
    mounted() {
        this.forceReload()
    }
};
</script>
