<template>
    <div>
        <v-row>
            <v-col cols="2">
                <v-menu offset-y dense>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            small
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                            Server: {{ selectedServerName }}
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(item, index) in serverData"
                            v-on:click="changeServer(item)"
                            :key="index"
                        >
                            <v-list-item-title>{{
                                item.server_name
                            }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="3">
                <v-text-field
                    class=""
                    v-model="routingSearch"
                    outlined
                    dense
                    append-icon="mdi-search"
                    label="Search routing"
                    single-line
                    hide-details
                >
                </v-text-field>
            </v-col>

            <v-col cols="5"></v-col>

            <v-col cols="4" class="text-right">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="blue-grey"
                            :loading="loading"
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click="resetFileInput"
                        >
                            <v-icon dark dense> mdi-file-upload </v-icon>
                            <input
                                type="file"
                                ref="routingFile"
                                @change="
                                    (event) => readFile(event.target.files[0])
                                "
                                accept=".csv"
                                style="display: none"
                            />
                        </v-btn>
                    </template>
                    <span>Upload settings from CSV</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="blue-grey"
                            class="ml-2"
                            :loading="loading"
                            small
                            v-bind="attrs"
                            v-on="on"
                        >
                            <download-excel
                                :data="filterSortData"
                                :fields="csvFields"
                                style="display: inline"
                                type="csv"
                                :name="
                                    'RoutingSettings_' +
                                    selectedServerName +
                                    '.csv'
                                "
                            >
                                <v-icon dark dense>
                                    mdi-microsoft-excel
                                </v-icon>
                            </download-excel>
                        </v-btn>
                    </template>
                    <span>Export settings as CSV</span>
                </v-tooltip>

                <v-btn
                    small
                    color="blue-grey"
                    class="ml-2"
                    @click="refreshRouting"
                    :loading="loading"
                    ><v-icon>mdi-refresh</v-icon></v-btn
                >
                <v-btn
                    small
                    color="primary"
                    class="ml-2"
                    :loading="loading"
                    v-on:click="toggleDialog('add')"
                    >ADD</v-btn
                >
                <v-btn
                    small
                    color="primary"
                    class="ml-2"
                    :disabled="!changed"
                    @click="updateData"
                    >SAVE & Apply</v-btn
                >
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-data-table
                    dense
                    :search="routingSearch"
                    :headers="routingHeader"
                    :items="filterSortData"
                    item-key="id"
                    class="elevation-0"
                    :loading="loading"
                    :items-per-page="-1"
                    id="home-table"
                    :hide-default-footer="false"
                    :header-props="{ sortIcon: null }"
                    fixed-header
                >
                    <template v-slot:body="props">
                        <draggable
                            :list="props.items"
                            tag="tbody"
                            @change="onDrag"
                        >
                            <!-- {{ props.items }} -->
                            <tr
                                v-for="(detail, index) in props.items"
                                :key="index"
                            >
                                <td>
                                    <v-icon small class="page__grab-icon"
                                        >mdi-arrow-all</v-icon
                                    >
                                </td>
                                <td>{{ detail.id }}</td>
                                <td>{{ detail.server_name }}</td>
                                <td>
                                    <div
                                        v-if="detail.filter_login.length >= 30"
                                    >
                                        {{ detail.filter_login.slice(0, 30)
                                        }}<v-tooltip top>
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                                ><span v-bind="attrs" v-on="on">
                                                    <v-icon small dark>
                                                        mdi-dots-horizontal
                                                    </v-icon>
                                                </span>
                                            </template>
                                            <span
                                                >Please use
                                                <v-icon color="green"
                                                    >mdi-microsoft-excel</v-icon
                                                >
                                                button to download and view the
                                                detail of this field</span
                                            >
                                        </v-tooltip>
                                    </div>
                                    <div v-else>{{ detail.filter_login }}</div>
                                </td>
                                <td>{{ detail.filter_group }}</td>
                                <td>{{ detail.filter_symbol }}</td>
                                <td>{{ detail.filter_symbol_group }}</td>
                                <td>{{ detail.percentage_a }}</td>
                                <td>{{ detail.percentage_b }}</td>
                                <td>{{ detail.book_a_name }}</td>
                                <td>{{ detail.book_b_name }}</td>
                                <td>
                                    <v-icon
                                        dense
                                        v-on:click="
                                            toggleDialog('update', detail)
                                        "
                                        :disabled="loading"
                                        >mdi-pen</v-icon
                                    >
                                    &nbsp;
                                    <v-icon
                                        dense
                                        :disabled="changed || loading"
                                        v-on:click="toggleRemoveDialog(detail)"
                                        >mdi-delete</v-icon
                                    >
                                </td>
                            </tr>
                        </draggable>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-dialog
            v-model="dialogRemove.visible"
            :fullscreen="dialogRemove.fullscreen"
            width="1200"
            :hide-overlay="true"
            :persistent="true"
        >
            <v-card class="pt-0">
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0">{{
                        dialogRemove.title
                    }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="white"
                        icon
                        @click="
                            dialogRemove.fullscreen = !dialogRemove.fullscreen
                        "
                        ><v-icon>mdi-arrow-expand</v-icon></v-btn
                    >
                    <v-btn
                        color="white"
                        icon
                        @click="dialogRemove.visible = false"
                        ><v-icon>mdi-close</v-icon></v-btn
                    >
                </v-app-bar>

                <v-card-actions>
                    <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                        ></v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-data-table
                                dense
                                :headers="removeHeader"
                                :items="removeData"
                                item-key="id"
                                class="elevation-0"
                                :loading="loading"
                                :items-per-page="-1"
                                id="home-table"
                                :hide-default-footer="true"
                                fixed-header
                                :header-props="{ sortIcon: null }"
                            >
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-actions>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="dialogRemove.visible = false"
                        :disabled="loading"
                        >Cancel</v-btn
                    >
                    <v-btn
                        color="red darken-1"
                        text
                        @click="deleteRow()"
                        :loading="loading"
                        ><span>Confirm</span></v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog.visible"
            :fullscreen="dialog.fullscreen"
            width="700"
            :hide-overlay="true"
            :persistent="true"
        >
            <v-card class="pt-0">
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: rgb(26, 57, 88)"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0">{{
                        dialog.title
                    }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="white"
                        icon
                        @click="dialog.fullscreen = !dialog.fullscreen"
                        ><v-icon>mdi-arrow-expand</v-icon></v-btn
                    >
                    <v-btn color="white" icon @click="dialog.visible = false"
                        ><v-icon>mdi-close</v-icon></v-btn
                    >
                </v-app-bar>

                <v-card-actions>
                    <v-row>
                        <v-col cols="12" class="pt-0">
                            <v-row
                                v-for="data in filterFields"
                                :key="data.text"
                                style="margin-top: 0px"
                            >
                                <v-col cols="4" class="pb-0">
                                    <label
                                        style="
                                            display: flex;
                                            align-items: end;
                                            height: 100%;
                                        "
                                    >
                                        {{ data.text }}
                                    </label>
                                </v-col>
                                <v-col cols="2" class="pb-0">
                                    <v-select
                                        v-model="inputMode[data.value]"
                                        :items="modeOptions"
                                        hide-details
                                        @change="() => onModeChange(data.value)"
                                    ></v-select>
                                </v-col>
                                <v-col
                                    cols="6"
                                    class="pb-0"
                                    style="display: flex"
                                >
                                    <b
                                        style="
                                            margin-top: 20px;
                                            padding-right: 10px;
                                        "
                                        >{{ inputPrefix[data.value] }}</b
                                    >
                                    <div style="width: -webkit-fill-available">
                                        <v-text-field
                                            v-model="dialog[data.value]"
                                            v-if="
                                                inputMode[data.value] ===
                                                'regex'
                                            "
                                            required
                                            :placeholder="'Please enter a new value'"
                                            hide-details="auto"
                                            single-line
                                        >
                                        </v-text-field>
                                        <v-autocomplete
                                            v-else
                                            v-model="dialog[data.value]"
                                            required
                                            hide-details="auto"
                                            single-line
                                            :placeholder="
                                                'Please select a value for ' +
                                                data.text
                                            "
                                            :items="filteredOptions[data.value]"
                                            item-text="name"
                                            item-value="name"
                                        ></v-autocomplete>
                                    </div>
                                    <b
                                        style="
                                            margin-top: 20px;
                                            padding-left: 10px;
                                        "
                                        >{{ inputSuffix[data.value] }}</b
                                    >
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="4" class="pt-4 pb-0">
                            <label
                                style="
                                    display: flex;
                                    align-items: end;
                                    height: 100%;
                                "
                            >
                                Book A
                            </label>
                        </v-col>
                        <v-col cols="8" class="pt-4 pb-0">
                            <v-autocomplete
                                dense
                                v-model="dialog.book_a_id"
                                :items="filteredBookData"
                                item-text="book_name"
                                item-value="book_id"
                                auto-select-first
                                chips
                                small-chips
                                :item-disabled="
                                    (item) => item.book_id == dialog.book_b_id
                                "
                                hide-details
                            >
                            </v-autocomplete>
                        </v-col>

                        <v-col cols="4" class="pt-0 pb-0">
                            <label
                                style="
                                    display: flex;
                                    align-items: end;
                                    height: 100%;
                                "
                            >
                                Percentage A
                            </label>
                        </v-col>
                        <v-col cols="8" class="pt-0 pb-0">
                            <v-text-field
                                single-line
                                v-model="dialog.percentage_a"
                                hide-details="auto"
                            />
                        </v-col>

                        <v-col cols="4" class="pt-4 pb-0">
                            <label
                                style="
                                    display: flex;
                                    align-items: end;
                                    height: 100%;
                                "
                            >
                                Book B
                            </label>
                        </v-col>
                        <v-col cols="8" class="pt-4 pb-0">
                            <v-autocomplete
                                dense
                                v-model="dialog.book_b_id"
                                :items="filteredBookData"
                                item-text="book_name"
                                item-value="book_id"
                                auto-select-first
                                chips
                                small-chips
                                :item-disabled="
                                    (item) => item.book_id == dialog.book_a_id
                                "
                                hide-details
                            >
                            </v-autocomplete>
                        </v-col>

                        <v-col cols="4" class="pt-0 pb-0">
                            <label
                                style="
                                    display: flex;
                                    align-items: end;
                                    height: 100%;
                                "
                            >
                                Percentage B
                            </label>
                        </v-col>
                        <v-col cols="8" class="pt-0 pb-0">
                            <v-text-field
                                v-model="dialog.percentage_b"
                                hide-details="auto"
                                single-line
                            />
                        </v-col>
                    </v-row>
                </v-card-actions>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="dialog.visible = false"
                        :disabled="dialog.loading"
                        >Cancel</v-btn
                    >
                    <v-btn
                        color="green darken-1"
                        text
                        @click="confirmAdd"
                        :loading="loading"
                        ><span>{{
                            dialog.type === "add" ? "Add" : "UPDATE"
                        }}</span></v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="removeWarning"
            width="600"
            :hide-overlay="true"
            :persistent="true"
        >
            <v-card class="pt-0">
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: #ff5252"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0"
                        ><v-icon>mdi-alert-circle</v-icon>
                        Warning</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-btn color="white" icon @click="removeWarning = false"
                        ><v-icon>mdi-close</v-icon></v-btn
                    >
                </v-app-bar>

                <v-card-text
                    class="text-h5 font-weight-bold"
                    style="padding: 24px 20px"
                >
                    For data safety reasons, please do not delete any rule after
                    modifying the order or content of rules
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="removeWarning = false"
                        >Confirm</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="refreshWarning"
            width="600"
            :hide-overlay="true"
            :persistent="true"
        >
            <v-card class="pt-0">
                <v-app-bar
                    flat
                    color="rgba(0, 0, 0, 0)"
                    style="background: #ff5252"
                >
                    <v-toolbar-title class="text-h6 white--text pl-0"
                        ><v-icon>mdi-alert-circle</v-icon>
                        Warning</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-btn color="white" icon @click="refreshWarning = false"
                        ><v-icon>mdi-close</v-icon></v-btn
                    >
                </v-app-bar>

                <v-card-text
                    class="text-h6 font-weight-bold"
                    style="padding: 24px 20px 0px"
                >
                    <div>
                        You have already edited at least one item. To continue
                        this action you need to update first.
                    </div>
                    <ul style="font-size: 18px">
                        <li>
                            press <v-icon>mdi-close</v-icon> to continue editing
                            rules.
                        </li>
                        <li>
                            press
                            <span style="color: #e53935">DISCARD</span> to
                            cancel all changes.
                        </li>
                        <li>
                            press <span style="color: #43a047">UPDATE</span> to
                            save all changes.
                        </li>
                    </ul>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="onDiscard"
                        >Discard</v-btn
                    >

                    <v-btn color="green darken-1" text @click="updateData"
                        >Update</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <oz-routing-dialog
            :visible.sync="ozRoutingDialogShow"
            :data="ozRoutingData"
        />
        <UpdateConfirmation
            type="routing"
            :addition="addition"
            :edition="edition"
            :header="routingHeader"
        />
    </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import Draggable from "vuedraggable";
import deepClone from "deep-clone";
import { snackbar } from "@components/mixins/snackbar";
import OzRoutingDialog from "./OzRoutingDialog";
import { getOrderRoutingMapping } from "@services/routing";
import UpdateConfirmation from "./ComponentUpdateConfirmation.vue";

export default {
    mixins: [snackbar],
    components: {
        Draggable,
        OzRoutingDialog,
        UpdateConfirmation,
    },
    mixins: [snackbar],
    data() {
        return {
            btnOzRoutingLoading: false,
            ozRoutingDialogShow: false,
            ozRoutingData: [],
            refreshWarning: false,
            removeWarning: false,
            changed: false,
            routingSearch: "",
            selectedServerName: "MT4",
            selectedServerId: -1,
            filterSortData: [],
            dialogRemove: {
                title: "Are you sure you want to remove this record?",
                fullscreen: false,
                visible: false,
            },
            dialog: {
                type: "add", // type add, update broker
                visible: false,
                fullscreen: false,
                title: "Add New Routing",
                id: null,
                server_id: null,
                filter_login: "",
                filter_group: "",
                filter_symbol: "",
                filter_symbol_group: "",
                percentage_a: 100,
                percentage_b: 0,
                book_a_id: null,
                book_b_id: null,
            },
            addition: [],
            edition: [],
            filterFields: [
                { text: "Filter Login", value: "filter_login" },
                { text: "Filter Group", value: "filter_group" },
                { text: "Filter Symbol", value: "filter_symbol" },
                { text: "Filter Symbol Group", value: "filter_symbol_group" },
            ],
            inputMode: {
                filter_login: "plain",
                filter_symbol: "plain",
                filter_group: "plain",
                filter_symbol_group: "plain",
            },
            filteredOptions: {
                filter_login: [],
                filter_group: [],
                filter_symbol: [],
                filter_symbol_group: [],
            },
            modeOptions: ["plain", "regex"],
        };
    },
    watch: {
        /**
         * Monitor server data change then set default server id and name
         * @param   {[type]}  nv  [nv description]
         * @param   {[type]}  ov  [ov description]
         * @return  {[type]}      [return description]
         */
        serverData(nv, ov) {
            if (nv.length !== 0) {
                this.selectedAdapterId = nv[0].adapter_id;
                this.selectedServerId = nv[0].id;
                this.selectedServerName = nv[0].server_name;
            }
        },
        selectedServerId(nv, ov) {
            let params = { server_id: this.selectedServerId };
            this.getRoutingsAction(params);
            this.changed = false;

            const myInterval = setInterval(() => {
                try {
                    const keys = Object.keys(this.filteredOptions);

                    keys.map((key) => {
                        this.filteredOptions[key] = this.options[key].filter(
                            (item) =>
                                item.server_id === this.selectedServerId &&
                                item.name.replaceAll(" ", "") !== ""
                        );
                        this.filteredOptions[key].unshift({
                            name: "*",
                            server_id: this.selectedServerId,
                        });
                    });
                    clearInterval(myInterval);
                } catch (e) {}
            }, 50);
        },
        /**
         * Assign routing data to temp variable filterSortData
         * @param   {[type]}  nv  [nv description]
         * @param   {[type]}  ov  [ov description]
         * @return  {[type]}      [return description]
         */
        routingData(nv, ov) {
            this.filterSortData = deepClone(nv);
        },
        loading(nv, ov) {
            if (!nv) {
                this.dialogRemove.visible = false;
                this.dialog.visible = false;
            }
        },
        "dialog.percentage_a": function (val) {
            if (val > 100) {
                this.dialog.percentage_a = 100;
            }
            this.dialog.percentage_b = 100 - val;
        },
        "dialog.percentage_b": function (val) {
            if (val > 100) {
                this.dialog.percentage_b = 100;
            }
            this.dialog.percentage_a = 100 - val;
        },
        newData(nv) {
            nv.book_a_name = this.filteredBookData.filter(
                (data) => data.book_id === nv.book_a_id
            )[0].book_name;
            nv.book_b_name = this.filteredBookData.filter(
                (data) => data.book_id === nv.book_b_id
            )[0].book_name;
            nv.server_name = this.serverData.filter(
                (data) => data.id === nv.server_id
            )[0].server_name;

            this.filterSortData.push(nv);
        },
    },
    computed: {
        ...mapState("SettingsRouting", [
            "routingData",
            "routingHeader",
            "loading",
            "originalData",
            "removeHeader",
            "removeData",
            "newData",
            "csvFields",
        ]),
        ...mapState("SettingsServer", ["serverData"]),
        ...mapState("SettingsBooks", ["bookData"]),
        ...mapState("Config", ["options"]),
        filteredBookData() {
            if (this.selectedServerId != -1 && this.bookData.length) {
                const brokerId = this.serverData.filter(
                    (server) => server.id == this.selectedServerId
                )[0].broker_id;
                const filteredBooks = this.bookData.filter(
                    (book) => book.broker_id === brokerId
                );
                return filteredBooks;
            } else {
                return [];
            }
        },
        inputPrefix() {
            let returnValue = {};
            Object.keys(this.inputMode).map(
                (key) =>
                    (returnValue[key] =
                        this.inputMode[key] === "plain" ? "" : "regex[")
            );
            return returnValue;
        },
        inputSuffix() {
            let returnValue = {};
            Object.keys(this.inputMode).map(
                (key) =>
                    (returnValue[key] =
                        this.inputMode[key] === "plain" ? "" : "]")
            );
            return returnValue;
        },
    },
    methods: {
        ...mapActions("SettingsRouting", [
            "getRoutingsAction",
            "postRoutingAction",
            "putRoutingAction",
            "deleteRoutingAction",
        ]),
        ...mapMutations("SettingsRouting", ["UPDATE_REMOVE_DATA"]),
        /**
         * Toggle remove dialog
         * @param   {[type]}  id  [id description]
         * @return  {[type]}      [return description]
         */
        toggleRemoveDialog(data) {
            if (this.changed) {
                this.removeWarning = true;
            } else {
                this.dialogRemove.visible = true;
                let temp = [data];
                this.UPDATE_REMOVE_DATA(temp);
            }
        },
        /**
         * Confirm to remove data record
         * @return  {[type]}  [return description]
         */
        deleteRow() {
            if (this.removeData.length !== 0) {
                let params = {
                    id: this.removeData[0].id,
                    server_id: this.removeData[0].server_id,
                };
                this.deleteRoutingAction(params);
            }
        },
        /**
         * Change server id
         * @param   {[type]}  data  [data description]
         * @return  {[type]}        [return description]
         */
        changeServer(data) {
            this.selectedServerName = data.server_name;
            this.selectedServerId = data.id;
            this.selectedAdapterId = data.adapter_id;
        },
        onModeChange(key) {
            if (this.inputMode[key] === "regex") {
                this.dialog[key] = "";
            }
        },
        /**
         * Update all routing data
         * @return  {[type]}  [return description]
         */
        // routingData is the original data from db
        // filterSortData is the modified routing sequence
        updateData() {
            let diff = [];
            for (const [index, element] of this.routingData.entries()) {
                if (
                    JSON.stringify(element) !==
                    JSON.stringify(this.filterSortData[index])
                ) {
                    diff.push(this.filterSortData[index]);
                }
            }
            const extra = this.filterSortData.slice(this.routingData.length);
            const output = { routing: [...diff, ...extra] };

            this.putRoutingAction(output);

            this.refreshWarning = false;
            this.removeWarning = false;
            this.changed = false;
        },
        /**
         * On table row drag
         * @param   {[type]}  data  [data description]
         * @return  {[type]}        [return description]
         */
        onDrag(data) {
            const startIndex = data.moved.oldIndex;
            const endIndex = data.moved.newIndex;

            if (startIndex < endIndex) {
                const unchangedPartA = this.filterSortData.slice(0, startIndex);
                const changedPart = deepClone(
                    this.filterSortData.slice(startIndex, endIndex + 1)
                );
                const unchangedPartB = this.filterSortData.slice(endIndex + 1);

                for (var i = 0; i < changedPart.length - 1; i++) {
                    Object.keys(changedPart[i]).map((key) => {
                        if (
                            key !== "priority" &&
                            key !== "server_id" &&
                            key !== "id"
                        )
                            changedPart[i][key] = changedPart[i + 1][key];
                    });
                }

                Object.keys(changedPart[changedPart.length - 1]).map((key) => {
                    if (
                        key !== "priority" &&
                        key !== "server_id" &&
                        key !== "id"
                    )
                        changedPart[changedPart.length - 1][key] =
                            data.moved.element[key];
                });

                this.filterSortData = [
                    ...unchangedPartA,
                    ...changedPart,
                    ...unchangedPartB,
                ];
            } else {
                const unchangedPartA = this.filterSortData.slice(0, endIndex);
                const changedPart = deepClone(
                    this.filterSortData.slice(endIndex, startIndex + 1)
                );
                const unchangedPartB = this.filterSortData.slice(
                    startIndex + 1
                );

                for (var i = changedPart.length - 1; i > 0; i--) {
                    Object.keys(changedPart[i]).map((key) => {
                        if (
                            key !== "priority" &&
                            key !== "server_id" &&
                            key !== "id"
                        )
                            changedPart[i][key] = changedPart[i - 1][key];
                    });
                }

                Object.keys(changedPart[0]).map((key) => {
                    if (
                        key !== "priority" &&
                        key !== "server_id" &&
                        key !== "id"
                    )
                        changedPart[0][key] = data.moved.element[key];
                });

                this.filterSortData = [
                    ...unchangedPartA,
                    ...changedPart,
                    ...unchangedPartB,
                ];
            }
            if (
                JSON.stringify(this.filterSortData) ===
                JSON.stringify(this.routingData)
            ) {
                this.changed = false;
            } else {
                this.changed = true;
            }
        },
        /**
         * Toggle dialog to add book
         */
        toggleDialog(type, data = null) {
            this.dialog.visible = true;
            this.dialog.type = type;

            if (type === "add") {
                this.dialog.title = "Add New Routing";
                this.dialog.id = -1;
                this.dialog.server_id = this.selectedServerId;
                this.dialog.filter_login = "";
                this.dialog.filter_group = "";
                this.dialog.filter_symbol = "";
                this.dialog.filter_symbol_group = "";
                this.dialog.percentage_a = 100;
                this.dialog.percentage_b = 0;
                this.dialog.book_a_id = null;
                this.dialog.book_b_id = null;
                Object.keys(this.inputMode).forEach((key) => {
                    this.inputMode[key] = "plain";
                });
            } else {
                this.dialog.title = "Update Routing";
                this.dialog.id = data.id;
                this.dialog.server_id = data.server_id;

                Object.keys(this.inputMode).map((item) => {
                    let plainOrRegex = "plain";
                    if (
                        data[item].includes("regex[") &&
                        data[item].includes("]") &&
                        data[item][data[item].length - 1] === "]"
                    ) {
                        plainOrRegex = "regex";
                    }
                    this.inputMode[item] = plainOrRegex;
                    if (plainOrRegex === "plain") {
                        this.dialog[item] = data[item];
                    } else {
                        this.dialog[item] = data[item]
                            .replace("regex[", "")
                            .slice(0, -1);
                    }
                });

                this.dialog.percentage_a = data.percentage_a;
                this.dialog.percentage_b = data.percentage_b;
                this.dialog.book_a_id = data.book_a_id;
                this.dialog.book_b_id = data.book_b_id;
            }
        },
        confirmAdd() {
            if (this.dialog.type === "add") {
                if (
                    this.dialog.server_id === null ||
                    this.dialog.server_id === undefined ||
                    this.dialog.filter_login === "" ||
                    this.dialog.filter_group === "" ||
                    this.dialog.filter_symbol === "" ||
                    this.dialog.filter_symbol_group === "" ||
                    this.dialog.percentage_a === "" ||
                    this.dialog.percentage_b === "" ||
                    this.dialog.book_b_id === null ||
                    this.dialog.book_a_id === null
                ) {
                    this.snackBarDanger(
                        "Please fill all of the text fields before updating."
                    );
                    return;
                }

                const filterKeys = Object.keys(this.inputMode);
                filterKeys.map((key) => {
                    if (this.inputMode[key] === "regex") {
                        this.dialog[key] = "regex[" + this.dialog[key] + "]";
                    }
                });

                this.changed = true;
                this.postRoutingAction(this.dialog);
            } else {
                if (
                    this.dialog.filter_login === "" ||
                    this.dialog.filter_group === "" ||
                    this.dialog.filter_symbol === "" ||
                    this.dialog.filter_symbol_group === "" ||
                    this.dialog.percentage_a === "" ||
                    this.dialog.percentage_b === "" ||
                    this.dialog.book_a_id === null ||
                    this.dialog.book_b_id === undefined ||
                    this.dialog.book_a_id === undefined ||
                    this.dialog.book_b_id === null
                ) {
                    this.snackBarDanger(
                        "Please fill all of the text fields before adding new rules."
                    );
                    return;
                }

                const index = this.filterSortData.findIndex((object) => {
                    return object.id === this.dialog.id;
                });

                const filterKeys = Object.keys(this.inputMode);
                filterKeys.map((key) => {
                    if (this.inputMode[key] === "regex") {
                        this.dialog[key] = "regex[" + this.dialog[key] + "]";
                    }
                });

                const {
                    type,
                    visible,
                    title,
                    server_id,
                    id,
                    fullscreen,
                    ...changes
                } = this.dialog;

                Object.keys(changes).map((key) => {
                    this.filterSortData[index][key] = changes[key];
                });
                const aBookIndex = this.filteredBookData.findIndex(
                    (object) => object.book_id === changes.book_a_id
                );
                const bBookIndex = this.filteredBookData.findIndex(
                    (object) => object.book_id === changes.book_b_id
                );

                this.filterSortData[index].book_a_name =
                    this.filteredBookData[aBookIndex].book_name;
                this.filterSortData[index].book_b_name =
                    this.filteredBookData[bBookIndex].book_name;
                if (
                    JSON.stringify(this.filterSortData) ===
                    JSON.stringify(this.routingData)
                ) {
                    this.changed = false;
                } else {
                    this.changed = true;
                }
            }

            this.dialog.visible = false;
        },
        /**
         * Refresh routing data
         */
        refreshRouting() {
            if (this.changed) {
                this.refreshWarning = true;
            } else {
                let parmas = { server_id: this.selectedServerId };
                this.getRoutingsAction(parmas);
                this.refreshWarning = false;
            }
        },
        onDiscard() {
            this.changed = false;
            this.refreshRouting();
        },
        async getOzRouting() {
            this.btnOzRoutingLoading = true;
            try {
                const { data } = await getOrderRoutingMapping({
                    taker_connection_id_filter: this.selectedAdapterId,
                });
                if (data.code) {
                    this.snackBarDanger("Param Is Incorrect");
                } else {
                    this.ozRoutingData = data;
                    this.ozRoutingDialogShow = true;
                }
                this.btnOzRoutingLoading = false;
            } catch (error) {
                console.log(error);
                this.btnOzRoutingLoading = false;
            }
        },
        readFile(fileInput) {
            var reader = new FileReader();
            reader.onload = () => {
                var csv = reader.result
                    .replaceAll('"""', "")
                    .replaceAll('"=""', "");
                var lines = csv.split("\r\n");
                var result = [];
                var headers = lines[0].split(",");
                console.log(this.bookData);
                if (
                    JSON.stringify(headers) ===
                    JSON.stringify(Object.keys(this.csvFields))
                ) {
                    for (var i = 1; i < lines.length; i++) {
                        if (lines[i] === "") {
                            break;
                        }
                        var lineFailed = false;
                        var obj = {};
                        var currentLine = lines[i].split(",");
                        let bookA = undefined;
                        let bookB = undefined;
                        for (var j = 0; j < headers.length; j++) {
                            if (
                                headers[j] === "Server ID" &&
                                currentLine[j] !==
                                    JSON.stringify(this.selectedServerId)
                            ) {
                                lineFailed = true;
                            }
                            if (headers[j] === "Book A ID") {
                                bookA = parseInt(currentLine[j]);
                            }
                            if (headers[j] === "Book B ID") {
                                bookB = parseInt(currentLine[j]);
                            }
                            if (headers[j] === "Book A Name") {
                                const locatedId = this.bookData.find(
                                    (item) =>
                                        item.book_name.toUpperCase() ===
                                        currentLine[j].toUpperCase()
                                )?.book_id;
                                if (bookA !== locatedId) {
                                    obj[
                                        this.csvFields[
                                            headers[
                                                headers.indexOf("Book A ID")
                                            ]
                                        ]
                                    ] = !!locatedId
                                        ? locatedId.toString()
                                        : null;
                                }
                            }
                            if (headers[j] === "Book B Name") {
                                const locatedId = this.bookData.find(
                                    (item) =>
                                        item.book_name.toUpperCase() ===
                                        currentLine[j].toUpperCase()
                                )?.book_id;
                                if (bookB !== locatedId) {
                                    obj[
                                        this.csvFields[
                                            headers[
                                                headers.indexOf("Book B ID")
                                            ]
                                        ]
                                    ] = !!locatedId
                                        ? locatedId.toString()
                                        : null;
                                }
                            }

                            obj[this.csvFields[headers[j]]] = currentLine[j];
                        }
                        if (lineFailed) {
                            continue;
                        }
                        result.push(obj);
                    }

                    this.addition = [];
                    this.edition = [];
                    let add = [];
                    result.map((item) => {
                        add.push(item);
                    });
                    console.log(add);
                    if (add.length === 0) {
                        this.snackBarSuccess("All settings are up to date.");
                    } else {
                        this.addition = [...add];
                    }
                } else {
                    this.snackBarDanger("Input file is invalid.");
                }
            };
            reader.readAsText(fileInput);
        },
        resetFileInput() {
            this.$refs.routingFile.value = "";
            this.$refs.routingFile.click();
        },
    },
    mounted() {
        this.selectedAdapterId = this.serverData[0]?.adapter_id;
        this.selectedServerId = this.serverData[0]?.id;
        this.selectedServerName = this.serverData[0]?.server_name;
    },
};
</script>

<style scoped>
.v-tooltip__content.menuable__content__active {
    opacity: 1 !important;
    background-color: gray;
}
</style>
